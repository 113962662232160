import { parse } from "papaparse";
import { emailRex } from "./Helpers";
import { read, utils } from "xlsx";

export const payableFormat = {
  // contact fields
  name: {
    value: new RegExp(".+"),
    required: false,
  },
  email: {
    value: emailRex,
    required: true,
  },
  // transaction data
  amount: {
    value: new RegExp("^\\$?\\d+(\\.\\d{2})?$"),
    required: true,
  },
  statement: {
    value: new RegExp(".+"),
    required: true,
  },
  note: {
    value: new RegExp(".+"),
    required: false,
  },
};

export const contactFormat = {
  // contact fields
  name: {
    value: new RegExp(".+"),
    required: true,
  },
  email: {
    value: emailRex,
    required: true,
  },
  tags: {
    value: new RegExp(
      "^(supplier, customer|customer, supplier|supplier|customer)$"
    ),
    required: false,
  },
  account_type: {
    value: new RegExp("^(BUSINESS|PERSONAL)$"),
    required: false,
  },
  institution_number: {
    value: new RegExp("^\\d{3}$"),
    required: false,
  },
  transit_number: {
    value: new RegExp("^\\d{5}$"),
    required: false,
  },
  account_number: {
    value: new RegExp("^\\d{7,12}$"),
    required: false,
  },
  institution: {
    value: new RegExp(".+"),
    required: false,
  },
  holder_name: {
    value: new RegExp(".+"),
    required: false,
  },
  holder_email: {
    value: emailRex,
    required: false,
  },
  holder_address: {
    value: new RegExp(".+"),
    required: false,
  },
  holder_address_city: {
    value: new RegExp(".+"),
    required: false,
  },
  holder_address_postal_code: {
    value: new RegExp("^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$"),
    required: false,
  },
};

const isEmpty = (str) => str.length === 0;

export function formatColumnNames(columns) {
  return columns.map(function (columnName) {
    return spaceToCamel(columnName);
  });
}

export function validateJson(json, csvFormat) {
  let errors = [];
  for (const jsonObj of json) {
    for (const formatField in csvFormat) {
      let fieldFound = false;

      for (const field in jsonObj) {
        if (field === formatField) {
          fieldFound = true;
          const value = jsonObj[field];

          if (!value && csvFormat[formatField].required) {
            errors.push(
              new Error(`Could not find required field column '${formatField}'`)
            );
          }

          if (value) {
            const error = validateField(field, value, csvFormat);
            if (error) {
              errors.push(error);
            }
          }
        }
      }

      if (!fieldFound && csvFormat[formatField].required) {
        errors.push(
          new Error(`Could not find required field column '${formatField}'`)
        );
      }
    }
  }

  if (errors.length !== 0) {
    throw errors;
  }
}

export function validateColumns(columns, csvFormat) {
  columns = formatColumnNames(columns);
  Object.keys(csvFormat).forEach(function (columnName) {
    if (csvFormat[columnName].required && columns.indexOf(columnName) < 0) {
      throw new Error(`Could not find required column '${columnName}'`);
    }
  });
}

export function validateRow(row, columns, csvFormat) {
  columns = formatColumnNames(columns);
  for (const column of columns) {
    const value = row[columns.indexOf(column)];
    const error = validateField(column, value, csvFormat);
    if (error) {
      return error;
    }
  }
}

export function validateField(name, value, csvFormat) {
  if (!isEmpty(value) && !csvFormat[name].value.test(value)) {
    return new Error(
      `'${value}' is not a valid value for field: ${camelToSpace(name)}`
    );
  } else if (isEmpty(value) && csvFormat[name].required) {
    return new Error(`The '${name}' field is required and cannot be empty`);
  }
}

export function getRowContact(row, columns) {
  columns = formatColumnNames(columns);
  return {
    name:
      row[columns.indexOf("name")] ||
      row[columns.indexOf("email")].split("@")[0],
    email: row[columns.indexOf("email")],
    tags: [],
    bankAccounts: [],
  };
}

function spaceToCamel(str) {
  return str.toLowerCase().replace(/\s+(.)/g, function (delimiter) {
    console.log(delimiter);
    return delimiter.trim().toUpperCase();
  });
}

function camelToSpace(string) {
  return string
    .replace(/([A-Z])/g, " $1")
    .trim()
    .toLowerCase();
}

export async function xlsxToJson(file) {
  const data = await file.arrayBuffer();
  const workbook = read(data);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  return utils.sheet_to_json(worksheet);
}

export function csvToJson(file) {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();

      reader.onloadend = function () {
        const csvString = reader.result;
        const parseResult = parse(csvString);
        const data = parseResult.data;
        const errors = parseResult.errors;

        if (errors.length) {
          throw new Error(`File is not correct CSV`);
        }

        const header = data[0];
        const json = data.slice(1).map((row) => {
          let obj = {};
          for (let j = 0; j < header.length; j++) {
            if (row[j] === undefined || row[j] === null || row[j] === "") {
              continue;
            }

            let key = header[j].trim().toLowerCase();
            if (key.includes("(")) {
              key = key.split("(")[0].trim();
            }

            if (key === "amount") {
              const amount = row[j].trim();
              const decimalIndex = amount.indexOf(".");
              if (decimalIndex !== -1 && amount.length - decimalIndex === 2) {
                obj[key] = amount + "0";
              } else {
                obj[key] = amount;
              }
            } else {
              obj[key] = row[j].trim();
            }
          }
          return obj;
        });

        const filteredJson = json.filter((obj) => Object.keys(obj).length > 0);

        resolve(filteredJson);
      };

      reader.readAsText(file);
    } catch (error) {
      reject(error);
    }
  });
}
