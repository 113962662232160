/* eslint react/prop-types: 0 */
import ReactTable from "../../../../../../components/tables/ReactTable";
import { Button } from "reactstrap";
import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  fileTypes,
  formattedDollarValue,
  INTERNAL,
  pluralize,
} from "../../../../../../modules/Helpers";
import ContactTagBadge from "../../../../../../components/badge/ContactTagBadge";
import AddIcon from "../../../../../../assets/icons/AddIcon";
import ImportIcon from "../../../../../../assets/icons/ImportIcon";
import { AddCreditModal } from "./components/AddCreditModal";
import { useSimpleContext } from "../../../../../../contexts/SimpleContext";
import { useHistory } from "react-router-dom";
import warningAlert from "../../../../../../components/sweet_alert/WarningAlert";
import { calculateTotalAmount } from "../bulk_approval/ReviewBulkApproval";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { ContentPlaceHolder } from "../../../../../../components/ContentPlaceHolders";
import IndeterminateCheckbox from "components/tables/IndeterminateCheckbox";
import { downloadJsonToFile } from "../../../../../../modules/Helpers";
import {
  csvToJson,
  payableFormat,
  validateJson,
  xlsxToJson,
} from "modules/csv";
import { useToasts } from "react-toast-notifications";
import { allContacts } from "api_client/queries/contacts";
import useGQL from "api_client/UseGQL";
import { allBankAccounts } from "api_client/queries/bankAccounts";
export const BulkCreditDetails = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [state, setState] = useSimpleContext();
  const [transactions, setTransactions] = useState(state?.bulkCredits || []);
  const [csvContacts, setCsvContacts] = useState({});
  const [addCreditModalOpen, setAddCreditModalOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState();
  const [deleteAlert, setDeleteAlert] = useState();
  const [addBankAccountModal, setAddBankAccountModal] = useState();
  const fileInputRef = useRef(null);
  const { addToast } = useToasts();
  const [selectedRows, setSelectedRows] = useState([]);
  const many = transactions?.length > 1;
  const totalAmount = calculateTotalAmount(
    selectedRows?.map((row) => {
      return {
        amount: row.original.amount,
      };
    }) || []
  );
  let gqlHooks = useGQL(true);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleDownloadClick = () => {
    const data = [
      [
        "name (max 64 characters)",
        "email",
        "amount (min $10 & max $600000)",
        "statement (max 30 characters)",
        "note (max 200 characters)",
      ],
      [
        "Kevin",
        "kevin@mail.com",
        "388.81",
        "INV120",
        "Reimbursement for computer monitor",
      ],
      ["Company", "company@mail.com", "2200", "INV122", "cleaning fee"],
      [
        "AnotherCompany",
        "acompany@mail.com",
        "609.20",
        "INV122",
        "dishwasher repair",
      ],
      ["Hamzah", "hamzah@mail.com", "992.10", "INV121", "Year end bonus"],
      [
        "bojan",
        "bojan@mail.com",
        "9.89",
        "INV122",
        "Reimbursement for charger",
      ],
    ];

    downloadJsonToFile(data, "sample", "csv");
  };

  const addNewPayment = (newTransaction) => {
    newTransaction["id"] = transactions.length + 1;
    setTransactions([newTransaction, ...transactions]);
    setAddCreditModalOpen(false);
  };

  const submit = () => {
    const selectedTransactions = selectedRows.map((row) => {
      const selectedTransaction = transactions.find(
        (transaction) => transaction.id === row.original.id
      );
      return {
        ...selectedTransaction,
        rowId: row.id,
      };
    });
    setState({
      ...state,
      bulkCredits: selectedTransactions,
      csvContacts: csvContacts,
    });
    history.push("/bulk-payment/credit/create/review", {
      ...history.location.state,
      currStep: 3,
    });
  };

  const onUpdatePayment = (newTransaction, index) => {
    setEditingIndex(undefined);
    transactions.splice(index, 1, newTransaction);
    setTransactions([...transactions]);
  };

  const onRemove = (index) => {
    setDeleteAlert(null);
    transactions.splice(index, 1);
    setTransactions([...transactions]);
  };

  const defaultSelection =
    state?.selectedTransactions?.reduce((acc, draft) => {
      return { ...acc, [draft.rowId]: true };
    }, {}) || {};

  const confirmRemove = (row) => {
    warningAlert(
      () => setDeleteAlert(null),
      setDeleteAlert,
      <Button className="btn-warning" onClick={() => onRemove(row.index)}>
        Remove
      </Button>,
      `Are you sure you want to remove this payment totalling ${formattedDollarValue(
        row.original.amount
      )}?`
    );
  };

  async function handleImport(e) {
    setLoading(true);
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }

    let json = [];
    try {
      switch (file.type) {
        case fileTypes.CSV:
          json = await csvToJson(file);
          break;
        case fileTypes.XLSX:
          json = await xlsxToJson(file);
          break;
      }

      validateJson(json, payableFormat);

      await jsonToTransactions(json);

      addToast("File Imported", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (errors) {
      if (Array.isArray(errors)) {
        errors.forEach((error) => {
          addToast(error.message, {
            appearance: "error",
            autoDismiss: false,
          });
        });
      } else {
        addToast(errors.message, {
          appearance: "error",
          autoDismiss: false,
        });
      }
    } finally {
      fileInputRef.current.value = "";
      setLoading(false);
    }
  }

  async function jsonToTransactions(json) {
    let newTransactions = [];
    let newCsvContacts = {};
    let emails = json.map((contact) => {
      return contact.email;
    });
    let output = {
      identifier: true,
      name: true,
      createdAt: true,
      email: true,
      contactType: true,
      tags: true,
      bankAccounts: {
        identifier: true,
        state: true,
        institution: true,
        title: true,
        accountNumber: true,
      },
    };
    let input = {
      limit: 100,
      offset: 0,
      contacts: emails,
    };

    let response = await allContacts(input, output, gqlHooks);
    let bankAccountsDict = {};
    let identifersDict = {};
    response.data.forEach((contact) => {
      bankAccountsDict[contact.email] = contact.bankAccounts;
      identifersDict[contact.email] = contact.identifier;
    });
    newTransactions = json.map((row, index) => {
      let label = "";
      let bankAccounts = [];
      let identifier = null;

      if (row.email in bankAccountsDict) {
        let bankAccount =
          bankAccountsDict[row.email][bankAccountsDict[row.email].length - 1];
        label = bankAccount?.accountNumber;
        bankAccounts = bankAccountsDict[row.email];
      }
      if (row.email in identifersDict) {
        identifier = identifersDict[row.email];
      }
      let transactionObject = {
        id: transactions.length + index + 1, // Assign a unique ID starting from the length of existing transactions + 1
        toBankAccount: bankAccounts[bankAccounts.length - 1]?.identifier,
        toBankAccountLabel: label ? label : "Let recipient decide",
        amount: row.amount.toString().replace("$", ""),
        statement: row.statement,
        note: row.note,
        contact: {
          name: row.name || row.email.split("@")[0],
          email: row.email,
          tags: [],
          bankAccounts: bankAccounts,
        },
      };
      if (identifier) {
        transactionObject.contact.identifier = identifier;
        return transactionObject;
      } else {
        return transactionObject;
      }
    });

    setCsvContacts(Object.assign({}, csvContacts, newCsvContacts));
    setTransactions([...transactions, ...newTransactions]);
  }
  let columns = useMemo(
    () => [
      {
        id: "selection",
        accessor: "selection",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        Header: "Contact",
        accessor: "contact",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.value && cell.value.name ? cell.value.name : ""}
            </div>
            <div className="text-muted text-sm mb-1">
              {cell.row.original.direction === INTERNAL ? (
                "Internal Transfer"
              ) : (
                <>
                  {cell.value ? cell.value.email : "Pending Payment Acceptance"}
                </>
              )}
            </div>
          </>
        ),
      },
      {
        Header: "To bank account",
        accessor: "toBankAccountLabel",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <>
            <div
              className="font-weight-bold mt-1"
              style={{ lineHeight: "1.2" }}
            >
              {cell.value && cell.value}
            </div>
          </>
        ),
      },
      {
        Header: "Tags",
        accessor: "none",
        // eslint-disable-next-line react/prop-types,react/display-name
        Cell: ({ cell }) => (
          <div>
            {cell.row.original.contact === null ? (
              ""
            ) : (
              <div>
                {ContactTagBadge(
                  cell.row.original.contact?.tags || [],
                  cell.row.original?.identifier
                )}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Statement",
        accessor: "statement",
      },
      {
        Header: "Amount",
        accessor: "amount",
        style: { th: { textAlign: "right" } },
        // eslint-disable-next-line react/display-name
        Cell: ({ cell }) => (
          <div>
            <div>
              {cell.row.original.amount > 600000.0 ||
              cell.row.original.amount < 10.0 ? (
                <>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    <p style={{ color: "red" }}>
                      ({formattedDollarValue(cell.row.original.amount)})
                    </p>
                  </div>
                  <div className="text-muted text-sm">
                    <p style={{ color: "red" }}>
                      Please enter an amount between $10.00 and $600,000
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="font-weight-bold"
                    style={{ lineHeight: "1.2" }}
                  >
                    <p>({formattedDollarValue(cell.row.original.amount)})</p>
                  </div>
                  <div className="text-muted text-sm">Payable</div>
                </>
              )}
            </div>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ cell }) => (
          <div className="pull-right">
            <Button
              onClick={() => setEditingIndex(cell.row.index)}
              className="p-0 btn-link text-purple-rain"
              size="sm"
            >
              Edit <i className="tim-icons icon-simple-edit" />
            </Button>
            <Button
              onClick={() => confirmRemove(cell.row)}
              color="danger"
              className="p-0 btn-link"
              size="sm"
            >
              Remove
            </Button>
          </div>
        ),
      },
    ],
    [transactions]
  );

  return (
    <div className="content m-5">
      {addBankAccountModal}
      {deleteAlert}
      {(addCreditModalOpen || editingIndex !== undefined) && (
        <AddCreditModal
          toggle={() => {
            setAddCreditModalOpen(false);
            setEditingIndex(undefined);
          }}
          onSubmit={
            editingIndex !== undefined
              ? (newTr) => onUpdatePayment(newTr, editingIndex)
              : addNewPayment
          }
          initialState={
            editingIndex !== undefined ? transactions[editingIndex] : undefined
          }
          setAddBankAccountModal={setAddBankAccountModal}
        />
      )}
      <div className="d-flex flex-row justify-content-between">
        <h2 className="mb-3 font-weight-600 page-title">
          Add payments{" "}
          <span className="font-weight-500 text-muted text-xl">
            ({transactions?.length || 0})
          </span>
        </h2>
      </div>
      <div className={"d-flex flex-row justify-content-end"}>
        <Button
          className={"btn-simple btn-primary mr-2"}
          onClick={() => setAddCreditModalOpen(true)}
        >
          <AddIcon /> New payment
        </Button>
        <div className="fileinput">
          <input type="file" onChange={handleImport} ref={fileInputRef} />
          <Button
            disabled={loading}
            className={"btn-simple btn-primary mr-2"}
            onClick={handleClick}
          >
            <ImportIcon height={14} width={14} /> Import
          </Button>
        </div>
        <Button
          disabled={!selectedRows.length}
          onClick={submit}
          type="button"
          className="btn-primary text-black"
        >
          Review {pluralize("payment", many)}
        </Button>
      </div>
      <div className="d-flex flex-row justify-content-end">
        <button
          className={"btn-simple btn-primary mb-3"}
          onClick={handleDownloadClick}
          style={{
            border: "none",
            marginRight: "205px",
            padding: "1px 10px",
            fontSize: "11px",
          }}
        >
          Download sample
        </button>
      </div>
      <div className={"d-flex justify-content-end mb-3"}>
        <b>Total: {formattedDollarValue(totalAmount)}</b>
      </div>
      <ReactTable
        hidePagination={true}
        data={transactions}
        columns={columns}
        selectedRowIds={defaultSelection}
        styles="transaction-table"
        numTd={7}
        onRowSelect={(rows) => setSelectedRows(rows)}
        noContentPlaceholder={
          <ContentPlaceHolder
            titleText={"No payments added"}
            icon={faFileInvoiceDollar}
          />
        }
      />
    </div>
  );
};
