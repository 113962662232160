import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ContactsTable from "../../../components/tables/ContactsTable";
import useForm from "../../../hooks/UseForms";
import CustomTableSearch from "../../../components/forms/CustomTableSearch";
import { Button, UncontrolledCollapse } from "reactstrap";
import AddIcon from "../../../assets/icons/AddIcon";
import CreateContactModal from "../../../components/modals/CreateContactModal";
import ContactsFilters from "../../../components/modals/ContactsFilters";
import FilterIcon from "../../../assets/icons/FilterIcon";
import ImportIcon from "../../../assets/icons/ImportIcon";
import {
  downloadJsonToFile,
  fileTypes,
  formatPostalCode,
} from "../../../modules/Helpers";
import {
  contactFormat,
  csvToJson,
  validateJson,
  xlsxToJson,
} from "../../../modules/csv";
import { useToasts } from "react-toast-notifications";
import useGQL from "../../../api_client/UseGQL";
import { createBatchContacts } from "../../../api_client/mutations/contacts";
import { createBankAccountManual } from "../../../api_client/mutations/bankAccount";

function Contacts(props) {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { addToast } = useToasts();
  const { values } = useForm(null, () => null);
  const [filters, setFilters] = useState({});
  const [createContactModal, setCreateContactModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [numContacts, setNumContacts] = useState(0);
  let gqlHooks = useGQL();

  useEffect(() => {
    let count = 0;
    if (filters.contactTag) {
      count++;
    }
    if (filters.archived) {
      count++;
    }
    setFilterCount(count);
  }, [filters]);

  useEffect(() => {
    if (values.search !== undefined) {
      const timeoutId = setTimeout(
        () => setFilters({ ...filters, search: values.search }),
        500
      );
      return () => clearTimeout(timeoutId);
    }
  }, [values.search]);

  const getNumContacts = useCallback((num) => {
    setNumContacts(num);
  });

  const handleDownload = () => {
    const data = [
      [
        "name (Required)",
        "email (Required)",
        "tags (supplier and/or customer)",
        "account_type (BUSINESS or PERSONAL)",
        "institution_number (3 digits)",
        "transit_number (5 digits)",
        "account_number (7-12 digits)",
        "institution",
        "holder_name",
        "holder_email",
        "holder_address",
        "holder_address_city",
        "holder_address_postal_code",
      ],
      [
        "Kevin",
        "kevin@mail.com",
        "supplier",
        "BUSINESS",
        "777",
        "77777",
        "7777777",
        "RBC",
        "Kevin",
        "kevin@mail.com",
        "132 100 Ave",
        "Kelowna",
        "v3g 9s8",
      ],
      ["Company", "company@mail.com", "customer"],
      ["Another Company", "acompany@mail.com", '"customer, supplier"'],
      ["Dylan", "dylan@mail.com", '"supplier, customer"'],
      ["Bobby Su", "bobby@mail.com"],
    ];
    downloadJsonToFile(data, "Sample", "csv");
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  async function handleImport(e) {
    try {
      setLoading(true);
      e.preventDefault();
      let file = e.target.files[0];
      if (!file) {
        return;
      }

      let json = [];
      switch (file.type) {
        case fileTypes.CSV:
          json = await csvToJson(file);
          break;
        case fileTypes.XLSX:
          json = await xlsxToJson(file);
          break;
      }

      validateJson(json, contactFormat);
      const newContacts = await jsonToContacts(json);

      const response = await createBatchContacts(
        { contactsBatch: newContacts },
        {
          contacts: {
            identifier: true,
            email: true,
          },
        },
        gqlHooks
      );

      if (response) {
        for (const contact of response.contacts) {
          const values = await jsonToBankAccount(json, contact.email);
          if (values) {
            const data = {
              ...values,
              holderAddressCountry: "CA",
              title: "Chequing",
            };
            data.holderAddressPostalCode = formatPostalCode(
              data.holderAddressPostalCode
            );
            let response = await createBankAccountManual(
              { bankAccount: data, contactId: contact.identifier },
              {},
              gqlHooks
            );
          }
        }
      }

      addToast("File Imported", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (errors) {
      if (Array.isArray(errors)) {
        errors.forEach((error) => {
          addToast(error.message, {
            appearance: "error",
            autoDismiss: false,
          });
        });
      } else {
        addToast(errors.message, {
          appearance: "error",
          autoDismiss: false,
        });
      }
    } finally {
      fileInputRef.current.value = "";
      setLoading(false);
    }
  }

  async function jsonToContacts(json) {
    return json.map((row) => {
      return {
        name: row.name || row.email.split("@")[0],
        email: row.email,
        tags: row.tags ? row.tags.split(", ") : [],
        emailInvite: !row.account_type,
      };
    });
  }

  async function jsonToBankAccount(json, email) {
    const result = json.find((row) => row.email === email);

    if (Object.keys(result).length <= 3) {
      return null;
    }
    return {
      accountType: result["account_type"],
      institutionNumber: result["institution_number"],
      transitNumber: result["transit_number"],
      accountNumber: result["account_number"],
      institution: result["institution"],
      holderName: result["holder_name"],
      holderEmail: result["holder_email"],
      holderAddress: result["holder_address"],
      holderAddressCity: result["holder_address_city"],
      holderAddressPostalCode: result["holder_address_postal_code"],
    };
  }

  return (
    <Fragment>
      <CreateContactModal
        toggle={() => setCreateContactModal(!createContactModal)}
        isOpen={createContactModal}
      />
      <div className="content">
        <div className="d-flex flex-column justify-content-between">
          <h2 className="mb-3 page-title">
            Contacts{" "}
            <span className="text-warning">
              {filters.archived && "Archived"}
            </span>
            <span className="font-weight-500 text-muted text-xl mr-3">
              ({numContacts})
            </span>
          </h2>
          <div className="d-flex flex-row flex-grow-1 justify-content-end">
            <CustomTableSearch
              disableAmount
              filters={filters}
              setFilters={setFilters}
            />
            <div className="ml-3">
              <Button
                className="btn-simple btn-primary sp-button-simple"
                id="buttonToggler"
              >
                <FilterIcon width={15} height={15} strokewidth={3} />{" "}
                <span className="filter-btn-label">Filter</span>
                {filterCount > 0 && (
                  <Fragment>
                    {" "}
                    <span className="text-warning">{filterCount}</span>
                  </Fragment>
                )}
              </Button>
            </div>
            <div>
              <Button
                className="btn-primary sp-button"
                onClick={() => setCreateContactModal(!createContactModal)}
              >
                <AddIcon height={13} width={13} /> <span>Contact</span>
              </Button>
            </div>
            <div className="fileinput d-flex flex-column">
              <input type="file" onChange={handleImport} ref={fileInputRef} />
              <Button
                disabled={loading}
                className={"btn-primary sp-button"}
                onClick={handleClick}
              >
                <ImportIcon height={14} width={14} /> Import
              </Button>
              <button
                className={"btn-simple btn-primary ml-3 mb-3"}
                onClick={handleDownload}
                style={{
                  border: "none",
                  padding: "1px 5px",
                  fontSize: "11px",
                }}
              >
                Download sample
              </button>
            </div>
          </div>
          <UncontrolledCollapse toggler="#buttonToggler">
            <ContactsFilters filters={filters} setFilters={setFilters} />
          </UncontrolledCollapse>
        </div>
        <ContactsTable
          {...props}
          filters={filters}
          refresh={createContactModal || loading}
          getNumContacts={getNumContacts}
        />
      </div>
    </Fragment>
  );
}

export default Contacts;

Contacts.propTypes = {};
